import * as React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import styled from "styled-components";
import Layout from "../components/layout";

const MenuSection = styled.section`
  background-color: #ededed;
  margin: 0px 0px 20px 0px;
  padding: 20px 30px;
  border-radius: 5px;

  p {
    padding-bottom: 14px;

    @media only screen and (max-width: 600px) {
      border-bottom: 2px solid #ffffff;
      text-align: center;
    }
  }

  h4 {
    font-size: 30px;
    font-weight: 900;
    text-decoration: underline;
    @media only screen and (max-width: 600px) {
      text-align: center;
    }
  }

  b {
    display: block;
    margin-top: 10px;
    font-size: 18px;
    @media only screen and (max-width: 600px) {
      padding-bottom: 8px;
    }
  }
`;

// markup
const CateringPage = (data) => {
  return (
    <Layout title={"Catering"}>
      <MenuSection>
        <h4>Cartel Bundle: $12.95 per person</h4>
        <p>Build your own Fajita Tacos (2 per person)</p>
        <p>Comes with Lettuce, Tomatoes, Onions, Jalapenos, Shredded Cheese</p>
        <p>Chips and Salsa + Queso for 75 cents per person</p>

        <h4>El Grande: $14.95 per person (Big)</h4>
        <p>Build your own Fajita Tacos (2 per person)</p>
        <p>Comes with Lettuce, Tomatoes, Onions, Jalapenos, Shredded Cheese</p>
        <p>Chips and Salsa + Queso for 75 cents per person</p>
        <p>2 Side Options</p>

        <h4>Mas Grande: $17.95 per person (Bigger)</h4>
        <p>Build your own Fajita Tacos (2 per person)</p>
        <p>Comes with Lettuce, Tomatoes, Onions, Jalapenos, Shredded Cheese</p>
        <p>Chips and Salsa + Queso for 75 cents per person</p>
        <p>1 Starter Option + a 2nd for $1.50 per person</p>
        <p>2 Side Options</p>
      </MenuSection>

      <h3>Minimum $100 order for delivery.</h3>
      <h3>On-site Catering / Food Truck Available! Minimum of 60 people.</h3>
      <h3>18% Gratuity will be added to all catering orders.</h3>
    </Layout>
  );
};

export const query = graphql`
  query CateringQuery {
    allSanitySpecial {
      nodes {
        description
        name
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`;

export default CateringPage;
